/*

  страница_Фильтр
  страница_Карта
  создание_Эвента
  описание_Эвента
  личный_кабинет
  РАЗНОЕ
  ФОРМЫ

*/


*{
  margin: 0;
  padding: 0;
  outline: none;
}

html {height: 100%;}


body {
  width: 100%;
  height: 100%;
}
#root{
  height: 100%;
}

.App {
  text-align: center;
  font-size: calc(10px + 2vmin);
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #FFCC00;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}
.baseWrapper{
  background-color: #88A8BD;
  /*max-height: 100vh;*/
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  /*padding-bottom: 20vmin;*/
}
.baseWrapper:last-child{
  /*margin-bottom: 10vmin;*/
}
.baseHeadWr{
  background-color: #3FACC9;
  width: calc(100vw - 10vw);
  height: 10vh;
  padding: 0 5vw;
  border-bottom: 0.6vmin solid #4E6F74;
  display: flex;
  align-items: center;
}
.baseNavBack{
  font-size: calc(12px + 2vmin);
  color: #fff;
  font-weight: 400;
  text-decoration: underline;
}

.userPictWr{
  display: flex;
  align-content: center;
}
.userPictWr img{
  height: 8vh;
}
.pageNameWr{
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #4C6362;
  margin-top: 3vmin;
  padding: 3vmin 0;
}
.pageNameWr h1{
  font-size: calc(16px + 3vmin);
  margin: 0;
  color: #fff;
  text-transform: uppercase;
}

/* страница_Фильтр */
.filterWrapper{
  background-color: #5B7674;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  height: 100vh;
}
.filterHeadWr{
  margin-top: 10vh;
  background-color: #5CA595;
  width: calc(100vw - 10vw);
  padding: 3vmin 5vmin 4vmin;
  border-bottom: 0.8vmin solid #4C6362;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.filterLocation{
  font-size: calc(12px + 2vmin);
  margin-left: 2vmin;
  color: #fff;
  font-weight: 400;
  text-decoration: underline;
}
.filterHeadSearchWr{
  margin-top: 1vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.filterListWrapper{
  margin-top: 2vh;
  overflow: auto;
  padding-bottom: 25vmin;
}
.filterHeadInpWr{
  width: 65vw;
  padding: 2vmin 4vmin;
  border: 0.65vmin solid #4D897C;
  border-radius: 6vmin;
  display: flex;
  background-color: #fff;
  overflow: auto;
}
.filterHeadInp{
  border: none;
  width: 100%;
  margin-right: 1vmin;
}
.filterMapBtnWr{
  width: calc(100% - 10vw);
  text-align: left;
  color: #fff;
  font-weight: 400;
  text-decoration: underline;
  padding: 0 5vw;
}

.listUnit001{
  background: #DADADA;
  height: 12vmin;
  width:  100%;
  color: #343434;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5vmin;
}
.listUnitName{
  flex: 1;
  text-align: left;
}
.listUnitCount0{
  height: 12.5vmin;
  width: 12.5vmin;
  background-color: #5B7674;
  border-radius: 8vmin;
  border: 0.8vmin solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.listUnitCount{
  height: 12.5vmin;
  width: 12.5vmin;
  background-color: #FFCC00;
  color: #4C6362;
  font-size: calc(16px + 2vmin);
  font-weight: 1000;
  border-radius: 8vmin;
  border: 0.8vmin solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.listUnitCount span{
  padding-bottom: 0.8vmin;
}

/* страница_Карта */
.mapWr{
  min-height: 100vh;
  min-width: 100vw;
}
.mapCont{
  height: calc(100vh - 10vh - 0.4vh);
  width: 100vw;
}
.geoBtnWr{
  position: absolute;
  z-index: 1000;
  bottom: 50vmin;
  left: 2vmin;
}
.geoBtnWr img{
  width: 17vmin;
}

/* создание_Эвента */
.baseContWr{
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.newEventFormWr{
  background-color: #fefefe;
  margin-top: 5vmin;
  display: flex;
  flex-direction: column;
  border: 0.3vmin solid silver;
  width: calc(90vw - 10vmin);
  padding: 5vmin;
  box-shadow: 1px 5px 8px -5px black;
}
.newEventFormWr p{
  margin: 1vmin;
}
.newEventFormWr input:not(:first-child),
.newEventFormWr select,
.newEventFormWr textarea{
  margin-top: 5vmin;
}
.impWr{
  margin-top: 5vmin;
}
.impWr2{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.impWr2 input{
  margin-top: 5vmin;
  flex: 1;
  max-width: 45%;
}
.inpHeader{
  /*color: #fff;*/
}
.longInp{
  width: 70vw;
}
.longSel{
  width: 80vw;
}
.shortInp{
  width: calc(35vw - 10vmin);
  /*padding-right: 10vmin;*/
}
.inpTime{
  background: url("./img/decor/clock_001.svg") no-repeat right 1.5vmin center;
  background-color: #fff;
}
.inpDate{
  background: url("./img/decor/calendar_001.svg") no-repeat right 1.5vmin center;
  background-color: #fff;
}
.inpGeo{
  background: url("./img/decor/geo_001.svg") no-repeat right 1.5vmin center;
  background-color: #fff;
}
.actionBtnWr{
  display: flex;
  justify-content: space-between;
  background-color: #E4E4E4;
  padding: 5vmin;
  margin-top: 10vmin;
}
.formMarkerWr{
  position: relative;
}
.formMarker{
  position: absolute;
  height: calc(30px + 20vmin);
  bottom: -200%;
}
.formOptB{
  font-weight: 600;
}


/*описание_Эвента*/

.evFame_mainWr{
  background-color: #fefefe;
  margin: 5vmin auto;
  display: flex;
  flex-direction: column;
  border: 0.3vmin solid silver;
  width: 90vw;
  box-shadow: 1px 5px 8px -5px black;
}
.evFame_mainWrSub{
  margin: 3vmin;
}
/*.evFame_mainWr{
  margin-top: 6vmin;
  display: flex;
  flex-direction: column;
  background-color: #fefefe;
  width: 90vw;
}*/
.evFame_mainWr2{
  display: flex;
  justify-content: space-between;
  margin: 2vmin;
}
.evFame_mainWr3{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.chatBtnWr{
  position: relative;
}
.chatBtnWr img{
  background-color: #5B7674;
  border-radius: 30vmin 0 30vmin 30vmin;
  padding: 3vmin;
  position: absolute;
  bottom: 0;
  right: -5vmin;
}
.evFame_evHead{
  font-size: calc(10px + 4vmin);
  font-weight: 800;
  color: #3A6E84;
}
.evFame_evDisc{
  text-align: justify;
  margin-top: 2vmin;
}
.evFame_discGeo{
  margin-top: 6vmin;
  display: flex;
  background-color: #fefefe;
  width: calc(90vw - 4vmin);
  padding: 2vmin;
  font-weight: 500;
}
.evFame_discGeo img{
  margin-right: 2vmin;
}
.evFame_discTime{
  margin-top: 6vmin;
  width: 90vw;
  display: flex;
  justify-content: space-between;
  color: #FECC00;
  font-weight: 600;
  font-size: calc(16px + 4vmin);
}
.evFame_discTime div{
  display: flex;
  align-items: center;
}
.evFame_discTime div img{
  margin-right: 2vmin;
}
.evFame_discPlayers_wr{
  margin-top: 6vmin;
  width: 90vw;
  display: flex;
  flex-direction: column;
}
.evFame_discPlayers_head{
  color: #fefefe;
}
.evFame_discPlayersChain_wr{
  margin-top: 3vmin;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.evFame_discPlayersChain{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.evFame_discPlayersChain img{
  margin: 0   2vmin;
}
.evFame_ContWr0{
  width: 100%;
  background-color: #FFB30B;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
.evFame_ContWr1{
  background-color: #FFB30B;
  padding: 1vmin 2vmin;
  display: flex;
  align-items: center;
  font-size: calc(8px + 3vmin);
  font-weight: 600;
  margin-top: 2vmin;
}
.evFame_ContWr1 img{
  margin-right: 2vmin;
}
.evFame_ContName{
  font-size: calc(12px + 2vmin);
  font-weight: 500;
}
.evFormAddresMarkerWr{
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 5vmin;
}
.evFormAddresMarkerWr input{
  width: 100%;
}
.evFormAddresMarker{
  height: 10vmin;
  margin: 0 3vmin;
}

/* личный_кабинет */
.listUnitControlWr{
  display: flex;
  gap: 3vmin;
  margin: 0 4vmin;
}
.listUnitControlWr img{
  height: 7vmin;
}

/* ФОРМЫ РЕГИСТРАЦИИ / АВТОРИЗАЦИИ */

.baseFormWr0{
  margin-bottom: 2vmin;
  width: 60vw;
}

.baseFormWr{
  background-color: #fefefe;
  margin-top: 2vmin;
  display: flex;
  flex-direction: column;
  border: 0.3vmin solid silver;
  width: calc(100% - 10vmin);
  padding: 4vmin;
  box-shadow: 1px 5px 8px -5px black;
  align-items: center;
  font-weight: 500;
  color: #6E6E6E;
}

.baseFormInpWr{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 1vmin auto;
  width: 80%;
}
.baseFormInpWr input{
  width: calc(100% - 2vmin);
}
.formActionBtnWr{
  display: flex;
  justify-content: space-around;
  background-color: #E4E4E4;
  padding: 5vmin;
  width: calc(100% - 10vmin);
}
.lkUserFormStr{
  font-weight: 700;
}


/* РАЗНОЕ */
.App-link {
  color: #61dafb;
}

.plusBtn{
  position: absolute;
  bottom: 5vmin;
  right: 5vmin;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #B0CB1F;
  width: 14vmin;
  height: 14vmin;
  border-radius: 8vmin; 
  border: 4px solid #DBE285;
  font-weight: 800;
  font-size: calc(10px + 5vmin);
  color: #fff;
  box-shadow: 1px 5px 10px -5px black;
  cursor: pointer;
  z-index: 1000;
}
.plusBtn span{
  padding-bottom: 1.3vmin;
}
.baseActionBtnWr{
  display: flex;
  justify-content: flex-end;
  width: 90vw;
}
.baseActionBtn{
  float: right;
  padding: 0.5vmin 3vmin 1.2vmin;
  display: inline-block;
  background-color: #95CA3E;
  font-weight: 400;
  font-size: calc(8px + 2vmin);
  color: #fff;
  cursor: pointer;
  z-index: 1000;
}
.baseActionBtn span{
  padding-bottom: 0.9vmin;
}
.roundInpWr{
  display: flex;
  justify-content: center;
  align-items: center;
}
.roundInpWr img{
  margin: 0 5vmin;
  height: calc(10px + 4vmin);
}
.roundInp{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  width: 16vmin;
  height: 16vmin;
  border-radius: 10vmin; 
  border: 1.5vmin solid #FECC00;
  font-weight: 800;
  font-size: calc(18px + 6vmin);
  color: #5B7674;
}
.roundInp span{
  padding-bottom: 1.5vmin;
}
.divFlexBetween{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.closeWr{
  position: relative;
  text-align: right;
}
.closeWr img{
  position: absolute;
  z-index: 100;
  right: 0;
}


/* ФОРМЫ */
input{
  outline: none;
  border: 0.3vmin solid silver;
  height: calc(8px + 3vmin);
  padding: 1vmin;
  /*padding-right: 8vmin;*/
  /*background:url("./img/decor/clock_001.svg") no-repeat right 2vmin center;*/
  font-size: calc(8px + 2vmin);
}
select{
  outline: none;
  border: 0.3vmin solid silver;
  height: 11vmin;
  padding: 1vmin;
  font-size: calc(8px + 2vmin);
}
textarea{
  outline: none;
  border: 0.3vmin solid silver;
  min-height: 21vmin;
  padding: 2vmin;
  font-size: calc(8px + 2vmin);
}

.sysMessageWr{
  position: relative;
}
.pupSysMessage{
  position: absolute;
  padding: 5vmin 10vmin;
  background-color: #F8E390;
  top: 20vmin;
  right: 2vmin;
  box-shadow: 1px 5px 8px -5px black;
}
.sysMessage{
  text-align: center;
  width: 100%;
}

/*ДОПЫ*/
.borderHead01{
  border-top: 1.5vmin solid #3FADC8;
}

/* ПОП-АПы */
.back001{
  position: absolute;
  background-color: rgb(0,0,0,0.5);
  z-index: 500;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.waitGeoWr{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.waitGeoMarkerBack{
  height: 40vmin;
  position: absolute;
  /*transform: rotate(180deg);
  transition: 1s;*/
  animation-name: rotation;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes rotation {
  0% {
      transform:rotate(0deg);
  }
  100% {
      transform:rotate(360deg);
  }
}
.waitGeoMarker{
  height: 25vmin;
  position: absolute;
}


/*АНИМАЦИИ*/

.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.example-appear {
  opacity: 0.01;
}
 
.example-appear.anim-appear-active{
  opacity: 2;
  transition: opacity 5s ease-in;
}